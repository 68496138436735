import React from 'react'
import { Link } from 'gatsby'
import './404.scss'

export default function Error() {

    return (
        <div id="page_404">
            <div className="relative bloc_404">
                <h2>Page non trouvée</h2>
                <Link cover bg="white" direction="right" duration={2} to="/" className="primary_button button">Retour à l'accueil</Link>
            </div>
            <div className="overlay_absolute_6"></div>
        </div>
    )
}